.l-topBar-menu {
  li {
    .l-topBar {
      &__user {
        display: flex;
        align-items: center;
        .fa {
          font-size: 20px;
          padding-right: 6px;
        }
        &-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          &>* {
            line-height: 1.42857143;
            padding-left: 0;
            span:not(.pro) {
              padding-left: 0;
            }
          }
        }
        &-status {
          font-size: 11px;
        }
      }
    }
  }
}
.l-topBar-settings {
  & > a {
    &.l-topBar-settings {
      &__link {
        display: flex;
        align-items: center;
        text-decoration: none;
      }
    }
  }
  &__title {
    font-size: 13px;
    margin-right: 6px;
  }
}

.l-tag {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.annotation-box {
  margin-top: 15px;
  &__text {
    font-style: italic;
  }
}

.flex-price-tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .l-price {
    margin-top: 0;
    margin-right: 5px;
  }
  .l-tag {
    margin-right: 5px;
    margin-bottom: 0;
  }
  .l-price_na {
    margin-top: 0;
    margin-right: 5px;
  }
  @media(max-width: 768px) {
    flex-wrap: wrap;
    .l-tag, .l-price, .l-price_na {
      margin-bottom: 5px;
    }
  }
}

.hover-box {
  display: inline-block;
  position: relative;
  &:hover {
    .hover-box__link {
      transition: .1s ease-out;
      color: #68af55;
    }
    .hover-box__item {
      display: inline-block;
      white-space: nowrap;
    }
  }
  &__link {
    font-size: 16px;
    color: #777777;
    cursor: help;
    display: inline-block;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  &__item {
    transition: .2s ease-out;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #c7d9df;
    border-radius: 3px;
    color: #333333;
    position: absolute;
    z-index: 2;
    top: -50%;
    left: 23px;
    display: none;
    &:before {
      position: absolute;
      top: 10px;
      left: -10px;
      display: inline-block;
      border-top: 10px solid transparent;
      border-right: 10px solid #c7d9df;
      border-bottom: 10px solid transparent;
      content: '';
    }
    &:after {
      position: absolute;
      top: 10px;
      left: -9px;
      display: inline-block;
      border-top: 10px solid transparent;
      border-right: 10px solid #ffffff;
      border-bottom: 10px solid transparent;
      content: '';
    }
  }
}

.o-visible {
  overflow: visible;
}